<template>
  <a-layout class="rays-tuner-image-quality-detail">
    <a-layout-header class="header">
      <div class="back" @click="backClick">
        <svg-icon icon-class="chevron-left" class="top_icon" />
        <span class="back-con">画质检测详情</span>
      </div>
      <div class="tool">
        <router-link to="/mainhome/help" style="display: inline-flex">
          <svg-icon icon-class="helpful" class="top_icon" />
        </router-link>
        <my-cart />
        <station-message />
      </div>
    </a-layout-header>
    <div class="content">
      <div class="left-con">
        <a-tabs default-active-key="1" @change="handleChangeTab" class="tab">
          <a-tab-pane
            key="1"
            :tab="`匹配成功 (${replaceShaders.length})`"
            class="shader-wraper"
          >
            <RecycleScroller
              id="replace_shader_create_frames_scroll"
              style="height: 100%"
              :items="replaceShaders"
              :item-size="100"
              v-slot="{ item }"
            >
              <div class="each-shader">
                <div class="title">
                  <span>{{ handleShader(item, 'shader') }}</span>
                  <svg-icon icon-class="checkmark" class="icon checkmark" />
                </div>
                <div class="pat">{{ handleShader(item, 'pat') }}</div>
              </div>
            </RecycleScroller>
          </a-tab-pane>
          <a-tab-pane
            key="2"
            :tab="`匹配失败 (${noReplaceShaders.length})`"
            class="shader-wraper"
          >
            <RecycleScroller
              id="no_replace_shader_create_frames_scroll"
              style="height: 100%"
              :items="noReplaceShaders"
              :item-size="100"
              v-slot="{ item }"
            >
              <div class="each-shader">
                <div class="title" style="color: #d01010">
                  <span>{{ handleShader(item, 'shader') }}</span>
                  <svg-icon icon-class="close" class="icon close" />
                </div>
                <div class="pat">{{ handleShader(item, 'pat') }}</div>
              </div>
            </RecycleScroller>
          </a-tab-pane>
        </a-tabs>
        <a-button @click="downloadFile" class="download_btn">下载Shader</a-button>
      </div>

      <div class="chart-view">
        <div class="view-header">
          <div class="title">{{ task_id }}</div>
          <a-button @click="addDelErrTask" class="addtask_btn">剔除并重新检测</a-button>
          <div class="info">
            <span class="item"
              >PAT优化率:
              <span style="color: #604bdc">{{ optimizeRate }}%</span></span
            >
            <span class="item"
              >平均误差:
              <span style="color: #604bdc">{{ errorAvg }}</span></span
            >
            <span class="item">所属游戏: {{ game }}</span>
            <span class="item">平台: {{ device }}</span>
          </div>
        </div>
        <div class="view-content">
          <result-charts
            v-if="chartData.length"
            :chartData="chartData"
            @submitPoint="submitPoint"
          />
        </div>
      </div>

      <div class="right-con">
        <diff-pic-view
          ref="diffPicRef"
          :pics="pics"
          :detectData="detectData"
          @openComparePic="openPic"
          id-name="quality_tool"
          @boundChange="openPic"
        />
      </div>
    </div>
  </a-layout>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  toRefs,
  reactive,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';
import MyCart from '@/views/shader-detect/MyCart.vue';
import StationMessage from '@/views/pat-manager/StationMessage';
import ResultCharts from '@/components/charts/lineCharts.vue';
import DiffPicView from '@/components/DiffPicView';
import { message } from 'ant-design-vue';
// import { number } from "echarts";
import router from '@/routes';
// import { removeAllInfo, setEnterpriseHomeInfo } from "@/models/tunerStorage";
import store from '@/vuex/store';
import api from '@/api/shader-detect';
import { getBaseUrl } from '@/api/service';
import { convertRes2Blob } from '@/utils/download'
const engineurl = getBaseUrl('engine');

// const config = {
//   engineurl: "http://192.168.50.249:8099",
// };
export default defineComponent({
  name: 'ImageQualityDetail',
  components: { MyCart, StationMessage, ResultCharts, DiffPicView },
  setup() {
    const matchData = reactive({
      errorAvg: '',
      device: '',
      game: '',
      optimizeRate: '', // 优化率
      noReplaceShaders: [], //失败
      replaceShaders: [], //成功
    });
    const route = useRoute();
    const pat = route.params.pat;
    const task_id = route.params.task_id;
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const chartData = reactive([]);
    const pics = ref([]);
    const diffShow = ref(false);
    const currentFrame = ref(0);
    const detectData = reactive({
      frame: '',
      error: '',
    });
    const handleChangeTab = (index) => {
      console.log(index, $_email);
    };
    // 返回
    const backClick = () => {
      router.go(-1);
    };
    const handleShader = computed(() => {
      return (shader, type) => {
        if (type === 'shader') {
          return shader.split('-')[1];
        } else {
          return shader.split('-')[0];
        }
      };
    });
    // 点击选中帧
    const submitPoint = (frame, error) => {
      currentFrame.value = frame;
      detectData.frame = frame;
      detectData.error = error;
      let _pics = [
        {
          id: 0,
          reference: {
            // 原始图片
            url: `${engineurl}/api/sopt/simplifier/getErrorDetectionOriginImgByFrame?user=${$_email}&pat=${pat}&task_id=${task_id}&frame=${frame}`,
          },
          variant: {
            // 变种图片
            url: `${engineurl}/api/sopt/simplifier/getErrorDetectionReplaceImgByFrame?user=${$_email}&pat=${pat}&task_id=${task_id}&frame=${frame}`,
          },
        },
      ];
      pics.value = _pics;
    };
    // 获取折线数据方法
    const handleGetErrorDetectionChart = async () => {
      const params = {
        pat: pat,
        user: $_email,
        task_id: task_id,
      };
      const res = await api.getErrorDetectionChart(params);
      if (!res) return;
      if (res.code == 0) {
        if (res.point) {
          chartData.push(...res.point);
        }
        // 初始换帧效果对比
        const { max_error_frame, max_frame_error } = res;
        currentFrame.value = max_error_frame;
        submitPoint(max_error_frame, max_frame_error);
      } else {
        message.error('获取数据失败：' + res.detail);
      }
    };
    // 获取匹配数据方法
    const handleGetErrorDetectionOptimizeInfo = async () => {
      const params = {
        pat: pat,
        user: $_email,
        task_id: task_id,
      };
      const res = await api.getErrorDetectionOptimizeInfo(params);
      if (!res) return;
      if (res.code == 0) {
        const {
          errorAvg,
          device,
          optimizeRate,
          game,
          noReplaceShaders,
          replaceShaders,
        } = res;
        matchData.errorAvg = errorAvg;
        matchData.device = device;
        matchData.optimizeRate = optimizeRate * 100;
        matchData.game = game;
        matchData.noReplaceShaders = noReplaceShaders || [];
        matchData.replaceShaders = replaceShaders || [];
      } else {
        message.error('获取数据失败：' + res.detail);
      }
    };
    //
    const openPic = (currentPic, bound) => {
      getComparePicture(bound);
    };
    const diffPicRef = ref(null);
    const getComparePicture = (bound) => {
      const _contrastPic = `${engineurl}/api/sopt/simplifier/getErrorDetectionCompareImgByFrame?user=${$_email}&pat=${pat}&task_id=${task_id}&frame=${currentFrame.value}&bound=${bound}`;
      diffPicRef.value.clickContrast(_contrastPic);
    };


    const downloadFile = async () => {
      const params = {
        type: 0,
        user: $_email,
        pat: pat,
        task_id: task_id,
      };
      const res = await api.downloadShader(params, { returnRes: true, responseType:'blob' });
      if (!res) return;
      if (res.statusText == 'OK' && !res.data.code) {
        convertRes2Blob(res);
      } else {
        message.error('获取下载数据失败:' + res.data.detail);
      }
    };

    const addDelErrTask = async()=>{
      const _len = matchData.replaceShaders.length;
      if ( _len <= 1) {
        message.warn('匹配shader数为'+_len+'无法创建shader剔除任务!')
        return;
      }
      const params = {
        user: $_email,
        pat: pat,
        task_id: task_id,
        user_id: $_user.uid,
        device: matchData.device 
      };
      const res = await api.addDelErrorTask(params);
      if (!res) return;
      if (res.code == 0) {
        message.success('剔除后，重新提交画质检测任务成功！');
      } else {
        message.error('剔除后，重新提交画质检测任务失败：' + res.detail + '！');
      }
    }
    onMounted(() => {
      handleGetErrorDetectionOptimizeInfo();
      handleGetErrorDetectionChart();
    });
    return {
      ...toRefs(matchData),
      backClick,
      handleChangeTab,
      chartData,
      handleShader,
      pics,
      diffShow,
      submitPoint,
      diffPicRef,
      openPic,
      detectData,
      task_id,
      downloadFile,
      addDelErrTask
    };
  },
});
</script>

<style scoped lang="scss">
.rays-tuner-image-quality-detail {
  width: 100wh;
  height: 100vh;
  background: #ffffff;
  overflow: auto;
}
.header {
  height: 48px;
  padding: 0 35px 0 40px;
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #3c3c3c;
  .back {
    height: 100%;
    align-items: center;
    display: flex;
  }
  .back:hover {
    color: #1890ff;
    cursor: pointer;
  }
  .tool {
    display: flex;
    align-items: center;
  }
  .top_icon {
    width: 24px;
    height: 24px;
    color: #aaa;
  }
}

/* content */
.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
}
.left-con {
  width: 288px;
  .download_btn {
    margin-left: 80px;
  }
}
.left-con .tab {
  height: calc(100% - 45px);
  :deep(.ant-tabs-nav-scroll) {
    padding: 0 24px;
  }
  :deep(.ant-tabs-bar) {
    margin: 0;
  }
  :deep(.ant-tabs-nav) {
    margin: 0;
    .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-tab-active {
      color: #604bdc;
    }
    .ant-tabs-tab:hover {
      color: #604bdc;
    }
  }
  :deep(.ant-tabs-ink-bar) {
    background-color: #604bdc;
  }
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.shader-wraper {
  height: calc(100vh - 100px);
  overflow: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  .each-shader {
    height: 97px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 24px;
    .title {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #3c3c3c;
      line-height: 22px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      .icon {
        width: 16px;
        height: 16px;
      }
      .checkmark {
        color: #8877ff;
      }
      .close {
        color: #d01010;
      }
    }
    .pat {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #aaaaaa;
      line-height: 20px;
      text-align: left;
    }
  }
}
.chart-view {
  flex: 1;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  .view-header {
    padding: 28px 40px 21px;
    border-bottom: 1px solid #f0f0f0;
    .title {
      width: 235px;
      display: inline-block;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #3c3c3c;
      text-align: left;
    }
    .addtask_btn {
      display: inline-block;
      margin-left: 40px;
    }                                                                
    .info {
      margin-top: 17px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8c8c;
      text-align: left;
      .item {
        margin-right: 48px;
      }
    }
  }
  .view-content {
    height: calc(100vh - 200px);
    padding: 20px 20px 20px 0;
  }
}
.right-con {
  width: 528px;
  padding: 24px;
}
</style>
