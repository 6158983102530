<template>
  <div class="chart_con">
    <div id="broken-line-echart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as Echarts from 'echarts';
import { toRaw } from 'vue';

let myChart = null;

export default {
  name: 'BrokenLineCharts',
  data() {
    return {};
  },
  props: {
    chartData: Array,
  },
  created() {
    this.$nextTick(() => {
      myChart = Echarts.init(document.getElementById('broken-line-echart'));
      this.loadEchart();
      this.clickPoint();
    });
  },
  mounted() {
    window.onresize = function() {
      myChart.resize();
    };
  },
  unmounted() {
    myChart.dispose();
  },
  methods: {
    clickPoint() {
      myChart.on('mouseup', 'series', (params) => {
        this.$emit('submitPoint', params.name, params.value);
      });
    },
    loadEchart() {
      const data = toRaw(this.chartData);
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          formatter: function(params) {
            let relVal = `<div style="text-align: left;">${params[0].seriesName}: ${params[0].name}</div>`;
            for (let i = 0; i < params.length; i++) {
              relVal += `<div style="text-align: left;">Error: ${params[i].value}</div>`;
            }
            return relVal;
          },
        },
        grid: {
          show: true,
          borderColor: '#D8D8D8 ',
          backgroundColor: '#FAFAFA',
        },
        xAxis: {
          name: 'frame',
          data: data.map(function(item) {
            return Object.keys(item)[0];
          }),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#AAAAAA',
              width: 1,
            },
          },
        },
        yAxis: {
          name: 'error',
          min: 0,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#AAAAAA',
              width: 1,
            },
          },
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            filterMode: 'none',
          },
          {
            type: 'slider',
            yAxisIndex: 0,
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            filterMode: 'none',
          },
        ],
        series: {
          type: 'line',
          name: 'Frame',
          symbolSize: 12,
          color: '#604BDC',
          lineStyle: {
            color: '#D5D0F3',
            width: 1,
          },
          // 折线拐点的样式
          itemStyle: {
            emphasis: {
              // 鼠标经过时：
              shadowColor: '#604BDC',
              shadowBlur: 20,
            },
          },
          data: data.map(function(item) {
            return Object.values(item)[0];
          }),
        },
      });
    },
  },
};
</script>
<style scoped>
.chart_con {
  height: calc(100% - 29px);
}
</style>
